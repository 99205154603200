import { createStore } from 'easy-peasy';
import users from './users';
import programs from './programs';
import dashboard from './dashboard';
import snackbar from './snackbar';
import patients from './patients';
import devices from './devices';
import notes from './notes';
import patient from './patient';
import { IStoreModel } from './types';
import activityLogs from './activityLogs';
import statusPatients from './statusPatients';
import insurances from './insurances';
import medications from './medications';
import auditLogs from './auditLogs';
import auth from './auth';
import patientNotificationRules from './patientNotificationRules';
import mobileUsers from './mobileUsers';
import notifications from './notifications';
import carePlanIcd10Codes from './carePlanIcd10Codes';
import carePlanMedications from './carePlanMedications';
import carePlanHospitalizations from './carePlanHospitalizations';
import carePlanGoals from './carePlanGoals';
import tableCustomizations from './tableCustomizations';

const store = createStore<IStoreModel>(
  {
    auth,
    users,
    programs,
    dashboard,
    snackbar,
    patients,
    devices,
    notes,
    patient,
    activityLogs,
    statusPatients,
    insurances,
    medications,
    auditLogs,
    patientNotificationRules,
    mobileUsers,
    notifications,
    carePlanIcd10Codes,
    carePlanMedications,
    carePlanHospitalizations,
    carePlanGoals,
    tableCustomizations,
  },
  {
    name: 'SymbolHealthStore',
  },
);

export default store;
