import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { IThunkCreateCarePlanGoal } from '../types';

const onCreateCarePlanGoal: IThunkCreateCarePlanGoal = thunk(
  // @ts-ignore
  async ({ addCarePlanGoal }, payload) => {
    const { patientId, data } = payload;
    const goal = await api.carePlanGoals.create(patientId, data).then(res => res.data);
    addCarePlanGoal(goal);
  },
);

export default onCreateCarePlanGoal;
