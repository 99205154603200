import { ICarePlanGoal } from '~/services/api/carePlanGoals/types';
import { IFormValues } from '../../popups/GoalModal/form/types';

const getEditValues = (goal: ICarePlanGoal): IFormValues => {
  const date = new Date(goal.date);

  return {
    reason: goal.reason,
    date: new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).toISOString(),
  };
};

export default getEditValues;
