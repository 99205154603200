import { action } from 'easy-peasy';
import { IActionAddCarePlanGoal } from '../types';

const addCarePlanGoal: IActionAddCarePlanGoal = action((state, payload) => {
  state.items = [payload, ...state.items].sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
});

export default addCarePlanGoal;
