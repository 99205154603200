import { TableType } from '~/services/api/enums';
import { ITableCustomizationsStore } from './types';

const initStore: ITableCustomizationsStore = {
  items: {
    [TableType.Patients]: [],
    [TableType.PatientOverview]: [],
  },
};

export default initStore;
