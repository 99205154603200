import { thunk } from 'easy-peasy';
import api from '~/services/api';
import { getRefreshToken, getClientId, deleteTokens } from '~/services/auth';
import { IThunkLogout } from '../types';

// @ts-ignore
const onLogout: IThunkLogout = thunk(async ({ reset, setAuthorized }) => {
  try {
    const refreshToken = getRefreshToken();
    const clientId = getClientId();

    await api.auth.logOut({
      refreshToken,
      clientId,
    });
  } catch {
    deleteTokens();
  } finally {
    reset();
    setAuthorized();
  }
});

export default onLogout;
